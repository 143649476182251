<template>
  <fieldset :disabled="disabled">
    <div>
      <b-form ref="margemForm" @submit="onSubmitMargem">
        <div class="animated fadeIn">
          <b-row class="justify-content-center">
            <b-col md="8">
              <b-card no-body>
                <b-card-header>
                  <i class="icon-note"></i> Definir margem de premiação.
                </b-card-header>
                <b-card-body>
                  <cite
                    >Defina o valor em percentual, que deseja aplicar como
                    prêmio ao realizar seus orçamentos e pedidos. Esse
                    percentual será carregado mas poderá ser alterado por item,
                    no momento que estiver realizando o orçamento para seu
                    cliente.</cite
                  >
                  <hr />
                  <b-row>
                    <b-col>
                      <div class="form-group" for="margem">
                        <label class="label-margem" for="margem">
                          % Margem
                          <span>*</span>
                        </label>
                        <money
                          class="text-right"
                          id="margem"
                          v-model="margem"
                        ></money
                        ><br />
                        <span
                          v-if="
                            margem >
                            $store.state.configPadrao.limiteMaximoMargem
                          "
                          id="info-erro-margem"
                          >Percentual máximo de margem é de 70%</span
                        >
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="form-group" for="margem">
                        <span class="alertaMargem"
                          >Para produtos com preços mínimos sugeridos pelo
                          fabricante ou que demandem registro de projeto para
                          alteração de preço, o percentual de margem indicado no
                          campo “% margem” não será respeitado, pois o sistema o
                          recalculará para se adequar ao valor mínimo sugerido.
                          Nesses casos, recomendamos que entre em contato com
                          seu consultor para negociações ou para o registro do
                          projeto.</span
                        >
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-card-footer>
                  <b-button
                    class="float-right"
                    type="submit"
                    variant="primary"
                    :disabled="
                      margem > $store.state.configPadrao.limiteMaximoMargem
                    "
                    >Salvar</b-button
                  >
                  <b-button
                    v-on:click="cancel()"
                    class="float-right mr-1"
                    type="button"
                    variant="danger"
                    >Cancelar</b-button
                  >
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-form>
      <b-form ref="logoForm" @submit="onSubmitLogo">
        <div class="animated fadeIn">
          <b-row class="justify-content-center">
            <b-col md="8">
              <b-card no-body>
                <b-card-header>
                  <i class="icon-note"></i> Personalize seus orçamentos e
                  pedidos.
                </b-card-header>
                <b-card-body>
                  <cite>
                    Insira sua logo para ter orçamentos e pedidos personalizados
                    com sua marca.
                  </cite>
                  <hr />
                  <b-row>
                    <b-col>
                      <image-uploader
                        name="file"
                        :imgUrl="imgUrl"
                        v-model="file"
                        v-on:onUpload="setFormData"
                        id="logo"
                      ></image-uploader>
                    </b-col>
                  </b-row>
                  <cite class="texto-arquivo">
                    O arquivo do logotipo da revenda deve ser uma imagem JPG ou
                    PNG, com no máximo 1MB de tamanho e no máximo 1080 pixels de
                    dimensão.
                  </cite>
                  <cite class="texto-arquivo">
                    <br />Recomendável tamanho imagem-> largura de 190px e
                    altura de 65px
                  </cite>
                </b-card-body>
                <b-card-footer>
                  <b-button
                    class="float-right"
                    :disabled="!isFormLogoValid"
                    type="submit"
                    variant="primary"
                    >Salvar</b-button
                  >
                  <b-button
                    v-on:click="cancel()"
                    class="float-right mr-1"
                    type="button"
                    variant="danger"
                    >Cancelar</b-button
                  >
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
  </fieldset>
</template>

<script>
import Revenda from "../../../services/revenda";
import ImageUploader from "../../../components/ui/ImageUploader";
import { baseUrls } from "../../../settings";
import GrupoPermissao from "../../../services/grupoPermissao";

export default {
  name: "Geral",
  components: { ImageUploader },
  data() {
    return {
      formData: null,
      resale: null,
      dismissSecs: 5,
      alertCountDown: 0,
      alertCountDown1: 0,
      file: null,
      margem: 0,
      imgUrl: null,
      disabled: false,
    };
  },
  computed: {
    isFormLogoValid() {
      return this.file != null;
    },
  },
  mounted() {
    this.validaPermissao();
    this.loadResale();
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/configuracao/geral").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    setFormData(formData) {
      this.formData = formData;
    },
    loadResale() {
      Revenda.getByCodigo(this.$store.state.login.resale.revenda_codigo).then(
        (response) => {
          this.resale = response.data.data;
          this.margem = this.resale.margem_pre_definida * 1;
          this.imgUrl = baseUrls.vue + this.resale.url_logo;
        }
      );
    },
    onSubmitLogo(event) {
      event.preventDefault();
      Revenda.setLogo(this.formData)
        .then((response) => {
          this.clearFormLogo();
          this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.clearFormLogo();
          this.$helper.showErrorResponse(e);
        })
        .finally(() => {
          this.loadResale();
        });
    },
    onSubmitMargem(event) {
      event.preventDefault();
      Revenda.setMargem(this.margem)
        .then((response) => {
          this.clearFormMargem();
          this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.clearFormMargem();
          this.$helper.showErrorResponse(e);
        })
        .finally(() => {
          this.loadResale();
        });
    },
    cancel() {
      this.$router.push("/");
    },
    clearFormLogo() {
      this.file = null;
    },
    clearFormMargem() {
      this.margem = 0;
    },
  },
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.label-margem {
  display: block;
}
.label-margem span,
#margem ~ p,
#info-erro-margem {
  color: red;
}
.texto-arquivo {
  font-size: 0.75rem;
  color: red;
}

.alertaMargem {
  color: #ff0000;
  font-style: italic;
}
</style>
